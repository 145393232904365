export const reports = {
    subTitle: {
        first: "Reports",
    },
    title: "Addressing The Needs In The North",
    items: [
        {
            title: "NWT Energy Policy 2023 Recommendations",
            documentUrl: "assets/pdf/pdf-document-1.pdf",
            description: "This briefing note is intended to provide context and recommendations for climate and energy related reports that are anticipated to be discussed at the Fall Sitting of the Legislative Assembly of the Northwest Territories from October 13 to November 3, 2022..."
        },
        {
            title: "The Cost of Addressing the Core Housing Needs in the Northwest Territories",
            documentUrl: "assets/pdf/pdf-document-2.pdf",
            description: "Core housing need in the Northwest Territories (NWT) remains persistently high and has even increased from its levels in 2000. Approximately 1 in 5 NWT households (20%) were in core housing need in 2000. By 2019 this statistic rose to nearly 1 in 4 households (23%; or 3,483 out of 14,760 households in the NWT), according to the 2019 Community Housing Survey (NWT Bureau of Statistics)..."
        },
        {
            title: "02-04-2022, Fort McPherson CEP (Final)",
            documentUrl: "assets/pdf/pdf-document-3.pdf",
            description: "The Teetł’it Zheh and Fort McPherson Community Energy Plan (CEP) was prepared by Richard Nerysoo. The CEP was made possible in part through the financial support of the Indigenous Off-Diesel Initiative (IODI). The CEP was supported by Cambium Indigenous Professional Services (CIPS) and Indigenous Clean Energy (ICE)..."
        },
    ]
};