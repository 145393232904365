export const projects = {
    subTitle: {
        first: "Our Projects",
        second: "& Events",
    },
    title: "A Highlight Reel Of Our Efforts",
    items: {
        projects: [
            {
                title: "Projects one",
                coverImage: "assets/images/jpg/project-images/project-2.jpg",
                description: "A note left for the stove workers working on the Energy Efficient Woodstove project by a happy Homeowner."
            },
            {
                title: "Projects two",
                coverImage: "assets/images/jpg/project-images/project-1.jpg",
                description: "Energy Efficient Woodstoves that were installed in the community."
            },
        ],
        display_board: [
            {
                title: "Display board one",
                coverImage: "assets/images/jpg/project-images/display-board-1.jpg",
                description: "The Dinjii Zhuh Solutions banner."
            },
            {
                title: "Display board two",
                coverImage: "assets/images/jpg/project-images/display-board-2.jpg",
                description: "Display Board Two"
            },
            {
                title: "Display board three",
                coverImage: "assets/images/jpg/project-images/display-board-3.jpg",
                description: "Display Board Three"
            },
            {
                title: "Display board four",
                coverImage: "assets/images/jpg/project-images/display-board-4.jpg",
                description: "Display Board Four"
            },
        ],
        events: [
            {
                title: "Event one",
                coverImage: "assets/images/jpg/project-images/event-1.jpg",
                description: "Training in BC."
            },
            {
                title: "Event two",
                coverImage: "assets/images/jpg/project-images/event-2.jpg",
                description: "Training in Harrison Hot Springs BC."
            },
            {
                title: "Event three",
                coverImage: "assets/images/jpg/project-images/event-3.jpg",
                description: "The Energy Champions."
            },
            {
                title: "Event four",
                coverImage: "assets/images/jpg/project-images/event-4.jpg",
                description: "Training in Harrison Hot Springs BC."
            },
            {
                title: "Event five",
                coverImage: "assets/images/jpg/project-images/event-5.jpg",
                description: "Indigenous Clean Energy Gathering in Vancouver -  July 27th 2023."
            },
        ],
        community: [
            {
                title: "Community picture one",
                coverImage: "assets/images/jpg/project-images/community-1.jpg",
                description: "Early Moon."
            },  
            {
                title: "Community picture two",
                coverImage: "assets/images/jpg/project-images/community-2.jpg",
                description: "Slumping – A consequence of permafrost melt."
            },
            {
                title: "Community picture three",
                coverImage: "assets/images/jpg/project-images/community-3.jpg",
                description: "White Swan Lifting off."
            },
            {
                title: "Community picture four",
                coverImage: "assets/images/jpg/project-images/community-4.jpg",
                description: "Traveling the Peel River."
            },
            {
                title: "Community picture five",
                coverImage: "assets/images/jpg/project-images/community-5.jpg",
                description: "The Sunset."
            },
            {
                title: "Community picture six",
                coverImage: "assets/images/jpg/project-images/community-6.jpg",
                description: "Grizzly on the Dempster Highway."
            },
            {
                title: "Community picture saven",
                coverImage: "assets/images/jpg/project-images/community-7.jpg",
                description: "The sunset at Fort McPherson."
            },
            {
                title: "Community picture eight",
                coverImage: "assets/images/jpg/project-images/community-8.jpg",
                description: "Fort McPherson , Northwest Territories."
            },
            {
                title: "Community picture nine",
                coverImage: "assets/images/jpg/project-images/community-9.jpg",
                description: "Community picture nine"
            },
            {
                title: "Community picture ten",
                coverImage: "assets/images/jpg/project-images/community-10.jpg",
                description: "Community picture ten"
            },
        ],
        hiking: [
            {
                title: "Title one",
                coverImage: "assets/images/jpg/project-images/hike-1.jpg",
                description: "Hiking"
            },
            {
                title: "Title two",
                coverImage: "assets/images/jpg/project-images/hike-2.jpeg",
                description: "Hiking"
            },
            {
                title: "Title three",
                coverImage: "assets/images/jpg/project-images/hike-3.jpg",
                description: "Hiking"
            },
            {
                title: "Title four",
                coverImage: "assets/images/jpg/project-images/hike-4.jpg",
                description: "Gardening"
            },
            {
                title: "Title five",
                coverImage: "assets/images/jpg/project-images/hike-5.jpg",
                description: "Gardening"
            },
            {
                title: "Title six",
                coverImage: "assets/images/jpg/project-images/hike-6.jpg",
                description: "Gardening"
            },
        ],
    }
};