// Configs
import { newsletter } from '../../config/newsletter';

export default function NewsLatterPageComponent() {
    return (
        <div className="max-w-screen-xl px-4 mx-auto lg:gap-8 mt-[100px]">
            <div className="grid grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 gap-14 max-lg:gap-8 my-5 max-md:px-5">
                {newsletter.map((item: any, index) => (
                    <div key={index} className="w-full">
                        <div className="border-2 border-line rounded-md overflow-hidden">
                            <a target="_blank" href={item.url}>
                                <img src={item.url} />
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
