import Button from "../Button";
import { heroSection } from "../../config/hero-section";
import PreloadImage from "../PreloadImage";

const HeroSection = () => {
    return (
        <section id="home" className="hero-section-cover relative">
            <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                <a href="/" className="absolute p-3 bg-white rounded-b-[60px] mt-[-255px] -ml-[50px] max-lg:hidden">
                    <PreloadImage
                        width={90}
                        height={90}
                        className={"w-[190px] h-[190px] max-lg:min-w-[50px] max-lg:min-h-[50px]"}
                        src="assets/images/png/logo.png"
                        alt="LOGO"
                    />
                </a>
                <div className="mr-auto place-self-center lg:col-span-7 max-lg:col-start-2">
                    <div className="font-[500] text-white text-[35px] mb-3 max-lg:text-center">
                        <span className="px-2 py-1 rounded mr-1 text-white bg-primary_light">{heroSection.subTitle.first}</span>{heroSection.subTitle.second}
                    </div>
                    <h1 className="max-w-4xl mb-4 text-[80px] max-lg:text-[55px] max-lg:leading-[65px] max-lg:text-center font-semibold leading-[90px] text-white tracking-[1px]" style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}>{heroSection.title}</h1>
                    <div className="flex items-center gap-4 mt-8 max-lg:block">
                        <Button className="border-2 bg-none py-3 text-[20px] font-semibold hover:translate-y-[-4px] transition max-lg:w-full max-lg:mb-3" name={heroSection.buttons.one.name} focus={heroSection.buttons.one.url} />
                        <Button className="border-2 bg-none py-3 text-[20px] font-semibold hover:translate-y-[-4px] transition max-lg:w-full" name={heroSection.buttons.two.name} focus={heroSection.buttons.two.url} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;