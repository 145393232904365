import { useEffect } from 'react';
// Components
import SubHeroSection from '../../components/SubHeroSection';
import NewsLatterPageComponent from '../../components/NewsLatter/index';

const Newslatter = ({ setLoad }: Props) => {

    useEffect(() => {
        setLoad(false);
    }, []);

    return (
        <div>
            <SubHeroSection title="Newsletters" />
            <NewsLatterPageComponent />
        </div>
    );
};

interface Props {
    setLoad: (value: boolean) => void;
}

export default Newslatter;