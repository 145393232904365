import Button from "../Button";
import { whoWeAre } from "../../config/who-we-are";

const WhoWeAre = () => {
    return (
        <div className="max-w-screen-xl px-4 mx-auto lg:gap-8 max-lg:px-6 pt-[150px] mt-[100px] max-md:pt-[150px]" id="who-we-are">
            <div className="grid grid-cols-2 items-center max-lg:grid-cols-1 gap-2 my-5">
                <div>
                    <div className="mr-auto place-self-center lg:col-span-7 max-lg:col-start-2">
                        <div className="font-[500] text-primary_light text-[35px] mb-3">
                            <span className="px-2 py-1 rounded mr-1 text-white bg-primary_light">{whoWeAre.subTitle.first}</span>{whoWeAre.subTitle.second}
                        </div>
                        <h1 className="max-w-4xl mb-4 text-[60px] max-lg:text-[45px] max-lg:leading-[60px] leading-[70px] font-semibold text-secondary custom-font">{whoWeAre.title}</h1>
                        <p className="text-[18px] text-text_light leading-[35px]">{whoWeAre.paragraph}</p>
                        <div>
                            <h2 className="text-secondary text-xl font-medium my-3">Advisory Board Members</h2>
                            <div className="flex items-start max-md:block gap-4">
                                <ul className="list-disc pl-5">
                                    <li className="who-we-are-list text-lg mb-2">Taig Connel</li>
                                    <li className="who-we-are-list text-lg mb-2">Wanda Pascal</li>
                                    <li className="who-we-are-list text-lg mb-2">Rachel Reindeer Francis</li>
                                </ul>
                                <ul className="list-disc pl-5">
                                    <li className="who-we-are-list text-lg mb-2">Rosalie Ross</li>
                                    <li className="who-we-are-list text-lg mb-2">Tena Blake</li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex items-center gap-4 mt-6 max-lg:mb-10">
                            <Button focus={whoWeAre.buttons.one.url} className="py-3 text-[20px] font-semibold duration-800 hover:translate-y-[-4px] transition max-lg:mb-3" name={whoWeAre.buttons.one.name} />
                        </div>
                    </div>
                </div>
                <div className="w-full h-[500px] max-md:h-[300px] overflow-hidden rounded-xl">
                    <img className="w-full h-full object-cover" src={whoWeAre.coverImage.url} alt={whoWeAre.coverImage.alt} />
                </div>
            </div>
        </div>
    );
};

export default WhoWeAre;