export const ourServices = {
    subTitle: {
        first: "What",
        second: "We Do",
    },
    title: "Community Sustainability Projects and Energy Efficiency Initiatives",
    services: [
        {
            name: "Develop Clean Energy",
            icon: "assets/images/svg/develop-clean-energy-icon.svg"
        },
        {
            name: "Energy Efficiency",
            icon: "assets/images/svg/energy-efficiency-icon.svg"
        },
        {
            name: "Sustainable Community Development",
            icon: "assets/images/svg/sustainable-community.svg"
        },
        {
            name: "Climate Action",
            icon: "assets/images/svg/climate-action.svg"
        },
        {
            name: "Indigenous Involvement",
            icon: "assets/images/svg/Indigenous.svg"
        },
        {
            name: "Capacity Building",
            icon: "assets/images/svg/capacity-building.svg"
        },
    ]
};