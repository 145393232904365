import { useEffect, useState } from "react";
import { reports } from "../../config/reports";
import Button from "../Button";

const Report = () => {
    const [reportList, setReportList] = useState<any>([]);
    const [viewMore, setViewMore] = useState(false);

    /**
     * Initial data loading
    */
    useEffect(() => {
        setReportList(reports.items.slice(0, 3));
    }, []);

    const viewMoreReports = () => {
        setViewMore(true);
        setReportList(reports.items);
    }

    const viewLessReports = () => {
        setViewMore(false);
        setReportList(reports.items.slice(0, 3));
    }

    return (
        <div id="reports" className="max-w-screen-xl px-4 mx-auto lg:gap-8 mt-[200px] max-md:mt-[250px]">
            <div className="text-center mb-[80px]">
                <div className="font-[500] text-primary_light text-[35px] mb-3">
                    <span className="px-2 py-1 rounded mr-1 text-white bg-primary_light">{reports.subTitle.first}</span>
                </div>
                <h1 className="text-center max-w-4xl mb-4 text-[60px] max-lg:text-[45px] max-lg:leading-[60px] leading-[70px] font-semibold text-secondary mx-auto custom-font">{reports.title}</h1>
            </div>
            <div className="max-w-full flex flex-wrap justify-center gap-5 max-lg:gap-8 my-5 max-md:px-5">
                {reportList.map((item: any, index: any) => (
                    <div
                        key={index} // Make sure to add a unique key for each rendered element
                        className="w-full group overflow-hidden bg-top border-2 rounded-xl transition-all duration-500 px-8 py-7 border-l-8 border-l-primary mb-4"
                    >
                        <h1 className="text-3xl font-medium mb-3">{item.title}</h1>
                        <p className="text-[18px] text-text_light leading-[30px] mb-4">
                            {item.description}
                        </p>
                        <Button focus={item.documentUrl} className="w-fit font-semibold text-lg py-3" imageUrl="assets/images/svg/download-icon.svg" name="Download Document" />
                    </div>
                ))}
            </div>
            {!viewMore ? <div className="flex justify-center mt-12 px-5">
                <button onClick={viewMoreReports} className="border-[2px] bg-none py-3 text-[20px] font-semibold hover:translate-y-[-4px] transition max-lg:w-full border-primary_light text-primary_light px-6 rounded-md" style={{ color: "#00BD3A !important" }}>
                    View More
                </button>
            </div> : <div className="flex justify-center mt-12 px-5">
                <button onClick={viewLessReports} className="border-[2px] bg-none py-3 text-[20px] font-semibold hover:translate-y-[-4px] transition max-lg:w-full border-primary_light text-primary_light px-6 rounded-md" style={{ color: "#00BD3A !important" }}>
                    View Less
                </button>
            </div>}
        </div>
    );
};

export default Report;
