import React from "react";
import { useNavigate } from "react-router-dom";

const Button = ({ name, imageUrl, className, focus, styles, type, disabled }: Props) => {
    const navigate = useNavigate();

    return (
        <div>
            {focus != null && focus != undefined ? <a href={focus} style={styles} className={"w-auto px-6 py-2 flex items-center justify-center text-nowrap text-text_default rounded bg-gradient-to-t from-primary to-primary_light " + className}>
                {imageUrl != null ? <img className="w-auto h-5 pr-2" src={imageUrl} alt="IMAGE" /> : ''}
                {name}
            </a> : <button disabled={disabled} style={styles} type={type != undefined ? type : "button"} className={"w-auto px-6 py-2 flex items-center justify-center text-nowrap text-text_default rounded bg-gradient-to-t from-primary to-primary_light " + className}>
                {imageUrl != null ? <img className="w-auto h-5 pr-2" src={imageUrl} alt="IMAGE" /> : ''}
                {name}
            </button>}
        </div>
    );
}

interface Props {
    name: string;
    imageUrl?: string;
    className?: string;
    focus?: string;
    styles?: object;
    type?: "button" | "submit" | "reset" | undefined;
    disabled?: true | false;
}

export default Button;