import { useEffect, useState } from "react";

const MoveToTopButton = () => {
    const [buttonCss, setButtonCss] = useState("-right-14");

    useEffect(() => {
        setButtonVisibility();
    }, []);

    /**
     * Onscroll event
     * */
    window.onscroll = () => {
        setButtonVisibility();
    }

    /**
     * Set the button visibility according to page position
     * */
    const setButtonVisibility = () => {
        if (window.pageYOffset === 0) {
            setButtonCss("-right-16");
        } else {
            setButtonCss("right-6")
        }
    }

    /**
     * Move to top
     * */
    const moveToTop = () => {
        window.scrollTo({
            top: 0,
        });
    }

    return (
        <div className={"py-3 fixed bottom-4 z-10 hover:bottom-6 transition-all duration-500 " + buttonCss}
             onClick={moveToTop}>
            <button
                className="p-2 rounded-md border-2 border-primary_light mt-7 bg-primary_light cursor-pointer relative flex max-md:text-lg items-center justify-center overflow-hidden transition-all">
                     <svg className="rotate-90" width="40" height="40" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="fill-white" d="M21.1927 29.7916L51.4583 29.7916C52.2257 29.7916 52.8689 30.0512 53.388 30.5703C53.9071 31.0894 54.1667 31.7326 54.1667 32.4999C54.1667 33.2673 53.9071 33.9105 53.388 34.4296C52.8689 34.9487 52.2257 35.2083 51.4583 35.2083L21.1927 35.2083L34.4635 48.4791C35.0052 49.0208 35.2647 49.6527 35.2422 50.3749C35.2196 51.0972 34.9375 51.7291 34.3958 52.2708C33.8542 52.7673 33.2222 53.0269 32.5 53.0494C31.7778 53.072 31.1458 52.8124 30.6042 52.2708L12.7292 34.3958C12.4583 34.1249 12.2665 33.8315 12.1536 33.5156C12.0408 33.1996 11.9844 32.8611 11.9844 32.4999C11.9844 32.1388 12.0408 31.8003 12.1536 31.4843C12.2665 31.1683 12.4583 30.8749 12.7292 30.6041L30.6042 12.7291C31.1007 12.2326 31.7213 11.9843 32.4661 11.9843C33.2109 11.9843 33.8542 12.2326 34.3958 12.7291C34.9375 13.2708 35.2083 13.914 35.2083 14.6588C35.2083 15.4036 34.9375 16.0468 34.3958 16.5885L21.1927 29.7916Z" />
                        </svg>
            </button>
        </div>
    );
};

export default MoveToTopButton;