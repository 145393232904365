import PreloadImage from "../PreloadImage";

const SubHeroSection = ({ title }: { title: string }) => {
    return (
        <section className="hero-section-cover sub-hero-section-cover relative">
            <div className="mx-auto max-w-7xl px-4 py-0">
                <a href="/" className="absolute p-3 bg-white rounded-b-[60px] mt-[-190px] -ml-[50px] max-lg:hidden">
                    <PreloadImage
                        width={90}
                        height={90}
                        className={"w-[190px] h-[190px] max-lg:min-w-[50px] max-lg:min-h-[50px]"}
                        src="assets/images/png/logo.png"
                        alt="LOGO"
                    />
                </a>
                <div className="w-full mx-auto text-left md:w-8/12 xl:w-9/12 md:text-center">
                    <h1 className="max-w-4xl mb-4 text-[80px] max-lg:text-[55px] max-lg:leading-[65px] max-lg:text-center font-semibold leading-[90px] text-white tracking-[1px]" style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}>{title}</h1>
                </div>
            </div>
        </section>
    );
};

export default SubHeroSection;