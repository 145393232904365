import React, { useCallback, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { newsletter } from '../../config/newsletter';
import Button from '../Button';

export default function App() {
    const [swiperRef, setSwiperRef] = useState<SwiperClass>();

    /**
     * Handle slide previous function
     * */
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    /**
     * Handle slide next function
     * */
    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    return (
        <div className="max-w-screen-xl px-4 mx-auto lg:gap-8 mt-[300px] max-md:mt-[250px]" id="newsletter">
            <div className="text-center mb-[80px]">
                <div className="flex items-center gap-3">
                    <button className="max-h-[70px] mr-auto p-1 cursor-pointer rounded hover:bg-primary/10 max-md:hidden"
                        onClick={handlePrevious}>
                        <svg width="50" height="50" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="fill-primary" d="M21.1927 29.7916L51.4583 29.7916C52.2257 29.7916 52.8689 30.0512 53.388 30.5703C53.9071 31.0894 54.1667 31.7326 54.1667 32.4999C54.1667 33.2673 53.9071 33.9105 53.388 34.4296C52.8689 34.9487 52.2257 35.2083 51.4583 35.2083L21.1927 35.2083L34.4635 48.4791C35.0052 49.0208 35.2647 49.6527 35.2422 50.3749C35.2196 51.0972 34.9375 51.7291 34.3958 52.2708C33.8542 52.7673 33.2222 53.0269 32.5 53.0494C31.7778 53.072 31.1458 52.8124 30.6042 52.2708L12.7292 34.3958C12.4583 34.1249 12.2665 33.8315 12.1536 33.5156C12.0408 33.1996 11.9844 32.8611 11.9844 32.4999C11.9844 32.1388 12.0408 31.8003 12.1536 31.4843C12.2665 31.1683 12.4583 30.8749 12.7292 30.6041L30.6042 12.7291C31.1007 12.2326 31.7213 11.9843 32.4661 11.9843C33.2109 11.9843 33.8542 12.2326 34.3958 12.7291C34.9375 13.2708 35.2083 13.914 35.2083 14.6588C35.2083 15.4036 34.9375 16.0468 34.3958 16.5885L21.1927 29.7916Z" />
                        </svg>
                    </button>
                    <div>
                        <div className="font-[500] text-primary_light text-[35px] mb-3">
                            <span className="px-2 py-1 rounded mr-1 text-white bg-primary_light">Newsletters</span>
                        </div>
                        <h1 className="text-center max-w-4xl mb-4 text-[60px] max-lg:text-[45px] max-lg:leading-[60px] leading-[70px] font-semibold text-secondary mx-auto custom-font">Check Out Our Latest Newsletters</h1>
                    </div>
                    <button className="max-h-[70px] ml-auto p-1 cursor-pointer rounded hover:bg-primary/10 max-md:hidden"
                        onClick={handleNext}>
                        <svg className="rotate-180" width="50" height="50" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="fill-primary" d="M21.1927 29.7916L51.4583 29.7916C52.2257 29.7916 52.8689 30.0512 53.388 30.5703C53.9071 31.0894 54.1667 31.7326 54.1667 32.4999C54.1667 33.2673 53.9071 33.9105 53.388 34.4296C52.8689 34.9487 52.2257 35.2083 51.4583 35.2083L21.1927 35.2083L34.4635 48.4791C35.0052 49.0208 35.2647 49.6527 35.2422 50.3749C35.2196 51.0972 34.9375 51.7291 34.3958 52.2708C33.8542 52.7673 33.2222 53.0269 32.5 53.0494C31.7778 53.072 31.1458 52.8124 30.6042 52.2708L12.7292 34.3958C12.4583 34.1249 12.2665 33.8315 12.1536 33.5156C12.0408 33.1996 11.9844 32.8611 11.9844 32.4999C11.9844 32.1388 12.0408 31.8003 12.1536 31.4843C12.2665 31.1683 12.4583 30.8749 12.7292 30.6041L30.6042 12.7291C31.1007 12.2326 31.7213 11.9843 32.4661 11.9843C33.2109 11.9843 33.8542 12.2326 34.3958 12.7291C34.9375 13.2708 35.2083 13.914 35.2083 14.6588C35.2083 15.4036 34.9375 16.0468 34.3958 16.5885L21.1927 29.7916Z" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className="max-w-[100%] flex flex-wrap justify-center gap-14 max-lg:gap-8 my-5 max-md:px-3">
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    loop={true}
                    onSwiper={setSwiperRef}
                    coverflowEffect={{
                        rotate: 20,
                        stretch: -50,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    pagination={false}
                    modules={[EffectCoverflow, Pagination]}
                >
                    {newsletter.slice(0, 5).map((item, index) => (
                        <SwiperSlide className="newsletter-item-card" key={index}>
                            <div className="border-2 border-line rounded-md overflow-hidden">
                                <a target="_blank" href={item.url}>
                                    <img src={item.url} />
                                </a>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div>
                    <Button focus={"/newsletter"} className="py-3 text-[20px] font-semibold duration-800 hover:translate-y-[-4px] transition max-lg:mb-3" name={"View More"} />
                </div>
            </div>
        </div>
    );
}
