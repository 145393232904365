export const projectReport = {
    coverImage: {
        url: "assets/images/jpg/projects-and-reports-image.jpg",
        alt: "Projects & Reports",
    },
    subTitle: {
        first: "Projects",
        second: "& Reports",
    },
    title: "Check Our Projects And Reports",
    items: {
        one: {
            title: "Projects",
            subTitle: "View our latest projects",
            iconUrl: {
                url: "assets/images/svg/project-icon.svg",
                alt: "Project",
            },
            url: "/project-and-report#projects",
        },
        two: {
            title: "Reports",
            subTitle: "View our latest reports",
            iconUrl: {
                url: "assets/images/svg/report-icon.svg",
                alt: "Reports",
            },
            url: "/project-and-report#reports",
        },
    },
};