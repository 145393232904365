import { useState } from "react";
// Components
import PreloadImage from "../PreloadImage";
import Button from "../Button";
import { header } from "../../config/header";

function Header() {
    const [menuVisible, setMenuVisible] = useState("-100%");

    /**
     * View menu handler
     * */
    const viewMenu = () => {
        setMenuVisible("0%");
    }

    /**
     * Hide menu handler
     * */
    const hideMenu = () => {
        setMenuVisible("-100%");
    }

    return (
        <header>
            <nav className="border-gray-200 px-4 lg:px-6 py-4 max-lg:py-2 bg-transparent">
                <div className="flex items-center mx-auto max-w-screen-xl">
                    {/* Mobile Logo */}
                    <a href="/" className="lg:hidden mr-auto flex items-center">
                        <PreloadImage
                            width={80}
                            height={80}
                            className={"mr-3 w-[70px] h-[70px] max-lg:w-[90px] max-lg:h-[90px] max-lg:min-w-[50px] max-lg:min-h-[50px]"}
                            src="assets/images/png/logo.png"
                            alt="LOGO"
                        />
                    </a>
                    <div className="flex max-lg:block items-center lg:w-full max-lg:fixed max-lg:top-0 max-lg:left-[-100%] max-lg:z-20 max-lg:bg-white max-lg:w-4/5 max-lg:h-full max-lg:overflow-y-auto max-lg:transition-[all] max-lg:duration-300 max-lg:shadow-2xl"
                        style={{ left: menuVisible }}>
                        <div className="flex items-center w-auto pr-3 max-lg:p-4">
                            <div className="w-[170px] max-lg:hidden"></div>
                            <a href="/" className="items-center hidden max-lg:flex">
                                <PreloadImage
                                    width={80}
                                    height={80}
                                    className={"mr-3 w-[70px] h-[70px] max-lg:w-[90px] max-lg:h-[90px] max-lg:min-w-[50px] max-lg:min-h-[50px]"}
                                    src="assets/images/png/logo.png"
                                    alt="LOGO"
                                />
                            </a>
                            <button className="navbar-close ml-auto p-2 rounded max-lg:bg-[#efefef] lg:hidden" onClick={hideMenu}>
                                <svg className="h-8 w-8 text-gray-400 cursor-pointer hover:text-gray-500"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>
                        </div>
                        <ul className="flex max-lg:block items-center font-medium lg:flex-row lg:space-x-8 max-lg:p-6 max-lg:pt-0 max-lg:px-0 max-lg:w-full relative">
                            {header.menu.map((item: any, index: number) => (
                                <li key={index} className="max-lg:py-4 max-lg:px-4 max-lg:hover:bg-primary">
                                    <a href={item.url} onClick={hideMenu}
                                        className="block py-2 pr-4 pl-3 lg:bg-transparent text-secondary font-sans font-medium uppercase lg:p-0 lg:hover:text-primary_light max-lg:hover:text-text_default transition-all text-[16px]" aria-current="page">{item.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex items-center max-lg:hidden">
                        <div className="flex items-center gap-3 mr-4">
                            {header.social.map((item: any, index: number) => (
                                <a key={index} href={item.redirectTo} className="flex items-center">
                                    <PreloadImage
                                        width={35}
                                        height={35}
                                        className={"mr-3 w-[60px] h-auto max-lg:h-6"}
                                        src={item.icon.url}
                                        alt={item.icon.alt}
                                    />
                                </a>
                            ))}
                        </div>
                        <Button focus="" name={header.button.name} imageUrl={header.button.iconUrl} />
                    </div>
                    <div className="lg:hidden" onClick={viewMenu}>
                        <button className="navbar-burger flex items-center p-3">
                            <svg className="block h-6 w-6 fill-primary_one-50" viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;