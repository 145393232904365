// Components
import { useEffect } from 'react';
import Project from '../../components/Project';
import Report from '../../components/Report';
import SubHeroSection from '../../components/SubHeroSection';

const ProjectReport = ({ setLoad }: Props) => {

    useEffect(() => {
        const hashValue = window.location.hash;
        // Check if the hash is not empty and starts with '#'
        if (hashValue && hashValue.startsWith('#')) {
            window.location.href = hashValue;
        }
        setLoad(false);
    }, []);

    return (
        <div>
            <SubHeroSection title="Projects & Reports" />
            <Project />
            <Report />
        </div>
    );
};

interface Props {
    setLoad: (value: boolean) => void;
}

export default ProjectReport;