import { useRef, useState } from "react";
import PopupModel from "../PopupModel";
import { projects } from "../../config/projects";

const Project = () => {
    const [showModal, setShowModal] = useState(false);
    const [modelTitle, setModelTitle] = useState("No Content Found!");
    const [modelDescription, setModelDescription] = useState("No Description Found");
    const [selectedOption, setSelectedOption] = useState("Projects");

    const handleChange = (event: any) => {
        setSelectedOption(event.target.value);
    };

    /**
     * Set the popup model data
     * @param data
     * */
    const setTheModelData = (data: PopupDataProps) => {
        setModelTitle(data.title);
        setModelDescription(data.description);
        setShowModal(true);
    }
    
    return (
        <div id="projects">
            {showModal ? (
                <PopupModel setShowModal={setShowModal} modelTitle={modelTitle} modelDescription={modelDescription} />
            ) : null}
            <div className="max-w-screen-xl px-4 mx-auto lg:gap-8 mt-[150px] max-md:mt-[250px]">
                {/* Title section */}
                <div className="text-center mb-[30px]">
                    <div className="font-[500] text-primary_light text-[35px] mb-3">
                        <span className="px-2 py-1 rounded mr-1 text-white bg-primary_light">{projects.subTitle.first}</span>{projects.subTitle.second}
                    </div>
                    <h1 className="text-center max-w-4xl mb-4 text-[60px] max-lg:text-[45px] max-lg:leading-[60px] leading-[70px] font-semibold text-secondary mx-auto custom-font">{projects.title}</h1>
                </div>
                {/* Button selectors */}
                <div className="grid w-full place-items-center mb-[50px] px-2">
                    <div className="grid w-[50rem] max-md:w-full grid-cols-5 max-md:grid-cols-2 rounded-md bg-gray-200 transition-all duration-500 truncate">
                        {["Projects", "Display Boards", "Events", "The Community", "Hiking"].map((option) => (
                            <div key={option}>
                                <input
                                    type="radio"
                                    name="option"
                                    id={option.toString()}
                                    value={option}
                                    className="peer hidden"
                                    checked={selectedOption == option ? true : false}
                                    onChange={handleChange}
                                />
                                <label
                                    htmlFor={option.toString()}
                                    className={`h-full flex items-center justify-center cursor-pointer select-none rounded-md p-2 text-center peer-checked:bg-primary_light peer-checked:font-bold font-medium peer-checked:text-white`}
                                >
                                    {option}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                {/* Project Content */}
                <div className={"max-w-full flex flex-wrap justify-center gap-5 max-lg:gap-8 my-5 max-md:px-5 " + (selectedOption == "Projects" ? "project-section-slide-animation" : "hidden")}>
                    {projects.items.projects.map((item: any, index) => (
                        <div
                            key={index}
                            className="w-full max-w-[400px] max-lg:max-w-[50%] max-md:max-w-[100%] group overflow-hidden bg-cover bg-no-repeat bg-top border-2 rounded-xl transition-all duration-500"
                            style={{
                                backgroundImage: `url(${item.coverImage})`,
                            }}
                        >
                            <div className="p-5">
                                <div className="bg-white relative rounded-lg p-5 px-7 mt-[300px] transition-all duration-500 -bottom-[265px] group-hover:bottom-[0px]">
                                    {/* <h2 className="text-primary_one-50 text-[30px] max-md:text-[25px] font-semibold mb-1">{item.title}</h2> */}
                                    <p className="text-[18px] text-text_light leading-[30px] mb-3 line-clamp-2">
                                        {item.description}
                                    </p>
                                    <button onClick={() => setTheModelData({
                                        title: item.title,
                                        description: item.description,
                                    })} type="button" className="text-primary_light text-lg cursor-pointer hover:font-semibold">Read More</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* Display Board */}
                <div className={"max-w-full flex flex-wrap justify-center gap-5 max-lg:gap-8 my-5 max-md:px-5 " + (selectedOption == "Display Boards" ? "project-section-slide-animation" : "hidden")}>
                    {projects.items.display_board.map((item: any, index) => (
                        <div
                            key={index}
                            className="w-full max-w-[400px] max-lg:max-w-[50%] max-md:max-w-[100%] group overflow-hidden bg-cover bg-no-repeat bg-top border-2 rounded-xl transition-all duration-500"
                            style={{
                                backgroundImage: `url(${item.coverImage})`,
                            }}
                        >
                            <div className="p-5">
                                <div className="bg-white relative rounded-lg p-5 px-7 mt-[300px] transition-all duration-500 -bottom-[265px] group-hover:bottom-[0px]">
                                    {/* <h2 className="text-primary_one-50 text-[30px] max-md:text-[25px] font-semibold mb-1">{item.title}</h2> */}
                                    <p className="text-[18px] text-text_light leading-[30px] mb-3 line-clamp-2">
                                        {item.description}
                                    </p>
                                    <button onClick={() => setTheModelData({
                                        title: item.title,
                                        description: item.description,
                                    })} type="button" className="text-primary_light text-lg cursor-pointer hover:font-semibold">Read More</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* Events */}
                <div className={"max-w-full flex flex-wrap justify-center gap-5 max-lg:gap-8 my-5 max-md:px-5 " + (selectedOption == "Events" ? "project-section-slide-animation" : "hidden")}>
                    {projects.items.events.map((item: any, index) => (
                        <div
                            key={index}
                            className="w-full max-w-[400px] max-lg:max-w-[50%] max-md:max-w-[100%] group overflow-hidden bg-cover bg-no-repeat bg-top border-2 rounded-xl transition-all duration-500"
                            style={{
                                backgroundImage: `url(${item.coverImage})`,
                            }}
                        >
                            <div className="p-5">
                                <div className="bg-white relative rounded-lg p-5 px-7 mt-[300px] transition-all duration-500 -bottom-[265px] group-hover:bottom-[0px]">
                                    {/* <h2 className="text-primary_one-50 text-[30px] max-md:text-[25px] font-semibold mb-1">{item.title}</h2> */}
                                    <p className="text-[18px] text-text_light leading-[30px] mb-3 line-clamp-2">
                                        {item.description}
                                    </p>
                                    <button onClick={() => setTheModelData({
                                        title: item.title,
                                        description: item.description,
                                    })} type="button" className="text-primary_light text-lg cursor-pointer hover:font-semibold">Read More</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* The Community */}
                <div className={"max-w-full flex flex-wrap justify-center gap-5 max-lg:gap-8 my-5 max-md:px-5 " + (selectedOption == "The Community" ? "project-section-slide-animation" : "hidden")}>
                    {projects.items.community.map((item: any, index) => (
                        <div
                            key={index}
                            className="w-full max-w-[400px] max-lg:max-w-[50%] max-md:max-w-[100%] group overflow-hidden bg-cover bg-no-repeat bg-top border-2 rounded-xl transition-all duration-500"
                            style={{
                                backgroundImage: `url(${item.coverImage})`,
                            }}
                        >
                            <div className="p-5">
                                <div className="bg-white relative rounded-lg p-5 px-7 mt-[300px] transition-all duration-500 -bottom-[265px] group-hover:bottom-[0px]">
                                    {/* <h2 className="text-primary_one-50 text-[30px] max-md:text-[25px] font-semibold mb-1">{item.title}</h2> */}
                                    <p className="text-[18px] text-text_light leading-[30px] mb-3 line-clamp-2">
                                        {item.description}
                                    </p>
                                    <button onClick={() => setTheModelData({
                                        title: item.title,
                                        description: item.description,
                                    })} type="button" className="text-primary_light text-lg cursor-pointer hover:font-semibold">Read More</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* The Community */}
                <div className={"max-w-full flex flex-wrap justify-center gap-5 max-lg:gap-8 my-5 max-md:px-5 " + (selectedOption == "Hiking" ? "project-section-slide-animation" : "hidden")}>
                    {projects.items.hiking.map((item: any, index) => (
                        <div
                            key={index}
                            className="w-full max-w-[400px] max-lg:max-w-[50%] max-md:max-w-[100%] group overflow-hidden bg-cover bg-no-repeat bg-top border-2 rounded-xl transition-all duration-500"
                            style={{
                                backgroundImage: `url(${item.coverImage})`,
                            }}
                        >
                            <div className="p-5">
                                <div className="bg-white relative rounded-lg p-5 px-7 mt-[300px] transition-all duration-500 -bottom-[265px] group-hover:bottom-[0px]">
                                    {/* <h2 className="text-primary_one-50 text-[30px] max-md:text-[25px] font-semibold mb-1">{item.title}</h2> */}
                                    <p className="text-[18px] text-text_light leading-[30px] mb-3 line-clamp-2">
                                        {item.description}
                                    </p>
                                    <button onClick={() => setTheModelData({
                                        title: item.title,
                                        description: item.description,
                                    })} type="button" className="text-primary_light text-lg cursor-pointer hover:font-semibold">Read More</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

interface PopupDataProps {
    title: string;
    description: string;
}

export default Project;