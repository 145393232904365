const CompanyNameExplain = () => {
    return (
        <div className="max-w-screen-xl px-4 mx-auto lg:gap-8 mt-[300px] max-md:mt-[250px]">
            <div className="text-center mb-[20px]">
                <div className="font-[500] text-primary_light text-[35px] mb-3">
                    <span className="px-2 py-1 rounded mr-1 text-white bg-primary_light">Meaning</span>and Significance
                </div>
                <h1 className="text-center max-w-4xl mb-0 text-[60px] max-lg:text-[45px] max-lg:leading-[60px] leading-[70px] font-semibold text-secondary mx-auto custom-font">What does Dinjii Zhuh Solutions Mean?</h1>
            </div>
            <div className="max-w-[100%] text-xl leading-10 text-center max-md:px-5 text-text_light">
                "Dinjii Zhuh" is a term from the Gwich'in language that translates to "First Peoples" or "original people," reflecting the Gwich'in identity as the original inhabitants of their traditional lands. This term emphasizes their deep-rooted connection to the land and their cultural heritage. Dinjii Zhuh Solutions refers to the innovation, creativity, and practices that originate from First Nations peoples, drawing on their rich cultural heritage and traditional knowledge. These solutions are deeply intertwined with the values, practices, and wisdom passed down through generations, ensuring that they are implemented with deep authenticity and respect. This highlights the importance of indigenous perspectives in addressing contemporary challenges.
            </div>
        </div>
    );
};

export default CompanyNameExplain;