import { useEffect } from "react";
// Components
import HeroSection from '../../components/HeroSection';
import WhoWeAre from "../../components/WhoWeAre";
import OurService from "../../components/OurService";
import ProjectReport from "../../components/ProjectReport";
import ContactUs from "../../components/ContactUs";
import NewsLetter from '../../components/NewsLatter/carousel';
import CompanyNameExplain from "../../components/CompanyNameExplain";

const Home = ({ setLoad }: Props) => {

    useEffect(() => {
        const hashValue = window.location.hash;
        // Check if the hash is not empty and starts with '#'
        if (hashValue && hashValue.startsWith('#')) {
            window.location.href = hashValue;
        }
        setLoad(false);
    }, []);

    return (
        <div>
            <HeroSection />
            <WhoWeAre />
            <OurService />
            <CompanyNameExplain/>
            <ProjectReport />
            <NewsLetter />
            <ContactUs />
        </div>
    );
};

interface Props {
    setLoad: (value: boolean) => void;
}

export default Home;