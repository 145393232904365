export const header = {
    logo: {
        url: "assets/images/png/logo.png",
        alt: "Logo",
    },
    social: [
        {
            icon: {
                url: "assets/images/svg/facebook-icon-primary.svg",
                alt: "Facebook"
            },
            redirectTo: "https://www.facebook.com/profile.php?id=100089549149479"
        },
    ],
    button: {
        name: "Contact: 867 952-2027",
        iconUrl: "assets/images/svg/header-call-icon.svg",
    },
    menu: [
        {
            name: "Home",
            url: "/#home",
        },
        {
            name: "Who We Are",
            url: "/#who-we-are",
        },
        {
            name: "Projects",
            url: "/project-and-report#projects",
        },
        {
            name: "Reports",
            url: "/project-and-report#reports",
        },
        {
            name: "Newsletter",
            url: "/newsletter",
        },
        {
            name: "Contact",
            url: "/#contact-form",
        },
    ]
};