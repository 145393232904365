// Libraries
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
// Components
import Button from "../Button";
// Custom files
import { contactUs } from "../../config/contact-us";
import { EMAIL_PUBLIC_KEY, EMAIL_SERVICE_KEY, EMAIL_TEMPLATE_ID, toastifyConfigs } from "../../config/constants";

const ContactUs = () => {
    const form = useRef<any>();
    const [buttonDisable, setButtonDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        control,
        reset,
        formState: { errors },
    } = useForm<Inputs>();

    /**
     * Send the email to the admins
     * @param event
    */
    const sendEmail = (event: any) => {
        event.preventDefault();
        // Disable the submit button
        setButtonDisable(true);
        // Add the processing text to the button
        setIsLoading(true);
        // Send the API request
        emailjs.sendForm(EMAIL_SERVICE_KEY, EMAIL_TEMPLATE_ID, form.current, EMAIL_PUBLIC_KEY)
            .then((result: any) => {
                toast.dismiss();
                // @ts-ignore
                toast.success("Your message sucessfully received. We'll contact you as soon as possible.", toastifyConfigs);
                // Enable the button
                setButtonDisable(false);
                // Remove the processing text from button
                setIsLoading(false);
                reset();
            }, (error: any) => {
                toast.dismiss();
                setButtonDisable(false);
                console.error('Error:', error.text);
                // @ts-ignore
                toast.error("Internal server error", toastifyConfigs);
            });
    };

    return (
        <div className="max-w-screen-xl px-4 mx-auto lg:gap-8 mt-[300px] max-md:mt-[250px] max-lg:px-6" id="contact-form">
            <div className="grid grid-cols-2 items-center max-lg:grid-cols-1 gap-14 my-5">
                <div>
                    <div className="lg:col-span-7 max-lg:col-start-2">
                        <div className="font-[500] text-primary_light text-[35px] mb-3">
                            <span className="px-2 py-1 rounded mr-1 text-white bg-primary_light">{contactUs.subTitle.first}</span>{contactUs.subTitle.second}
                        </div>
                        <h1 className="max-w-4xl mb-5 text-[60px] max-lg:text-[45px] max-lg:leading-[60px] leading-[70px] font-semibold text-secondary custom-font">{contactUs.title}</h1>
                        <form ref={form} onSubmit={sendEmail}>
                            <div className="grid grid-cols-2 max-md:grid-cols-1 gap-6 mb-6">
                                <div>
                                    <Controller
                                        defaultValue={""}
                                        control={control}
                                        name="first_name"
                                        rules={{
                                            required: true,
                                        }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <input type="text" name="first_name" onChange={onChange} value={value} className="border-[1.5px] border-line focus:border-primary_light outline-0 text-secondary text-[20px] rounded-md block w-full font-normal p-4 placeholder-placeholder_primary inset-5" placeholder="First Name *" required />
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        defaultValue={""}
                                        control={control}
                                        name="last_name"
                                        rules={{
                                            required: true,
                                        }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <input type="text" name="last_name" onChange={onChange} value={value} className="border-[1.5px] border-line focus:border-primary_light outline-0 text-secondary text-[20px] rounded-md block w-full font-normal p-4 placeholder-placeholder_primary inset-5" placeholder="Last Name *" required />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="mb-6">
                                <Controller
                                    defaultValue={""}
                                    control={control}
                                    name="email"
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <input type="email" name="email" onChange={onChange} value={value} className="border-[1.5px] border-line focus:border-primary_light outline-0 text-secondary text-[20px] rounded-md block w-full font-normal p-4 placeholder-placeholder_primary inset-5" placeholder="Email address *" required />
                                    )}
                                />
                            </div>
                            <div className="mb-6">
                                <Controller
                                    defaultValue={""}
                                    control={control}
                                    name="message"
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <textarea name="message" onChange={onChange} value={value} className="border-[1.5px] border-line focus:border-primary_light outline-0 text-secondary text-[20px] rounded-md block w-full font-normal p-4 placeholder-placeholder_primary inset-5 min-h-[200px]" placeholder="Message *" required></textarea>
                                    )}
                                />
                            </div>
                            <div>
                                <Button type="submit" disabled={buttonDisable} className="py-3 px-10 text-[20px] font-semibold duration-800 hover:translate-y-[-4px] transition max-lg:mb-3" name={isLoading ? "Processing..." : "Send"} />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="w-full">
                    <div className="h-[700px] max-md:h-[500px]" style={{ textDecoration: 'none', overflow: 'hidden', maxWidth: '100%', width: '100%', borderRadius: "10px" }}>
                        <div id="embed-map-display" style={{ height: '100%', width: '100%', maxWidth: '100%' }}>
                            <iframe
                                title="Google Maps"
                                style={{ height: '100%', width: '100%', border: '0' }}
                                frameBorder="0"
                                src={contactUs.mapUrl}
                            ></iframe>
                        </div>
                        <style>{`#embed-map-display img { max-width: none!important; background: none!important; font-size: inherit; font-weight: inherit; }`}</style>
                    </div>
                </div>
            </div>
        </div>
    );
};

type Inputs = {
    first_name: string;
    last_name: string;
    email: string;
    message: string;
}

export default ContactUs;
