export const whoWeAre = {
    coverImage: {
        url: "assets/images/jpg/who-we-are-image-original.jpg",
        alt: "Who We Are",
    },
    subTitle: {
        first: "Who",
        second: "We Are",
    },
    paragraph: "Dinjii Zhuh Solutions (incorporated in November 2021) is a Canadian-based Indigenous not-for-profit corporation leading and supporting community climate action. We promote implementation of sustainable development policies and encourage, promote and establish Indigenous climate action and adaptation. Dinjii Zhuh is is being led by Energy Champion, Richard Nerysoo. The team includes the Community Energy Coordinator, Community Energy Liaison and Energy Engagement Manager who work together on carrying out community sustainability projects and energy efficiency initiatives.",
    title: "An Indigenous Not for Profit Corporation",
    buttons: {
        one: {
            name: "Contact Us",
            url: "#contact-form",
        },
    },
};