const EMAIL_SERVICE_KEY = "service_u848prb";
const EMAIL_TEMPLATE_ID = "template_o6fu05j";
const EMAIL_PUBLIC_KEY = "ReQBbZpOg_cZokaqP";

let toastifyConfigs = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
};

export { 
    toastifyConfigs,
    EMAIL_SERVICE_KEY,
    EMAIL_TEMPLATE_ID,
    EMAIL_PUBLIC_KEY,
};