import { projectReport } from "../../config/project-report";
import PreloadImage from "../PreloadImage";

const ProjectReport = () => {
    return (
        <div className="max-w-screen-xl px-4 mx-auto lg:gap-8 mt-[300px] max-md:mt-[250px] max-lg:px-6" id="project-and-report">
            <div className="grid grid-cols-2 items-center max-lg:grid-cols-1 gap-14 my-5">
                <div className="w-full relative">
                    <div className="absolute bg-white -ml-16 -mt-16 max-md:-ml-0 max-md:left-[50%] max-md:translate-x-[-50%] p-8 max-md:p-4 rounded-full shadow-[0px_0px_15px_rgba(0,0,0,0.15)]">
                        <PreloadImage
                            width={200}
                            height={200}
                            className="w-[190px] h-[190px] max-md:w-[100px] max-md:h-[100px]"
                            src="assets/images/png/logo.png"
                            alt="LOGO"
                        />
                    </div>
                    <img className="w-full" src={projectReport.coverImage.url} alt={projectReport.coverImage.alt} />
                </div>
                <div>
                    <div className="lg:col-span-7 max-lg:col-start-2">
                        <div className="font-[500] text-primary_light text-[35px] mb-3">
                            <span className="px-2 py-1 rounded mr-1 text-white bg-primary_light">{projectReport.subTitle.first}</span>{projectReport.subTitle.second}
                        </div>
                        <h1 className="max-w-4xl mb-4 text-[60px] max-lg:text-[45px] max-lg:leading-[60px] leading-[70px] font-semibold text-secondary custom-font">{projectReport.title}</h1>
                        <div className="flex items-center max-sm:block gap-4 mt-8 max-lg:mb-10">
                            <a className="w-full" href={projectReport.items.one.url}>
                                <div className="w-full flex items-center border-[3px] border-line hover:border-primary_light hover:cursor-pointer transition duration-500 px-6 pl-3 py-3 rounded-lg max-sm:mb-5">
                                    <div className="mr-2">
                                        <img className="w-[80px] h-[80px]" src={projectReport.items.one.iconUrl.url} alt={projectReport.items.one.iconUrl.alt} />
                                    </div>
                                    <div>
                                        <h2 className="font-semibold text-[20px]">{projectReport.items.one.title}</h2>
                                        <span className="text-text_light">{projectReport.items.one.subTitle}</span>
                                    </div>
                                </div>
                            </a>
                            <a className="w-full" href={projectReport.items.two.url}>
                                <div className="w-full flex items-center border-[3px] border-line hover:border-primary_light hover:cursor-pointer transition duration-500 px-6 pl-3 py-3 rounded-lg">
                                    <div className="mr-2">
                                        <img className="w-[80px] h-[80px]" src={projectReport.items.two.iconUrl.url} alt={projectReport.items.two.iconUrl.alt} />
                                    </div>
                                    <div>
                                        <h2 className="font-semibold text-[20px]">{projectReport.items.two.title}</h2>
                                        <span className="text-text_light">{projectReport.items.two.subTitle}</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectReport;