export const newsletter = [
    {
        id: 1,
        url: "assets/images/jpg/newsletter/newsletter-1.png"
    },
    {
        id: 2,
        url: "assets/images/jpg/newsletter/newsletter-2.png"
    },
    {
        id: 3,
        url: "assets/images/jpg/newsletter/newsletter-3.png"
    },
    {
        id: 4,
        url: "assets/images/jpg/newsletter/newsletter-4.png"
    },
    {
        id: 5,
        url: "assets/images/jpg/newsletter/newsletter-5.png"
    },
];