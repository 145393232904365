import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// Pages
import Home from "./pages/Home";
import ProjectReport from "./pages/ProjectReport";
import Newslatter from "./pages/NewsLetter";
// Components
import Header from "./components/Header";
import Footer from "./components/Footer";
import PreLoader from "./components/PreLoader";
import MoveToTopButton from "./components/MoveToTopButton";
import { ToastContainer } from "react-toastify";
// Styles
import "./styles/tailwind.css";
import "./styles/custom.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
    const [load, setLoad] = useState<boolean>(true);

    return (
        <>
            <ToastContainer />
            <Router>
                <Header />
                <PreLoader visible={load} />
                <Routes>
                    <Route path="/" element={<Home setLoad={setLoad} />}></Route>
                    <Route path="/project-and-report" element={<ProjectReport setLoad={setLoad} />}></Route>
                    <Route path="/newsletter" element={<Newslatter setLoad={setLoad} />}></Route>
                </Routes>
                <MoveToTopButton />
                <Footer />
            </Router>
        </>
    );
}

export default App;