import { ourServices } from "../../config/services";

const OurService = () => {
    const boxShadowStyle = "0px 0px 20px rgba(0, 0, 0, 0.1)";

    return (
        <div className="max-w-screen-xl px-4 mx-auto lg:gap-8 mt-[300px] max-md:mt-[250px]">
            <div className="text-center mb-[80px]">
                <div className="font-[500] text-primary_light text-[35px] mb-3">
                    <span className="px-2 py-1 rounded mr-1 text-white bg-primary_light">{ourServices.subTitle.first}</span>{ourServices.subTitle.second}
                </div>
                <h1 className="text-center max-w-4xl mb-4 text-[60px] max-lg:text-[45px] max-lg:leading-[60px] leading-[70px] font-semibold text-secondary mx-auto custom-font">{ourServices.title}</h1>
            </div>
            <div className="max-w-[100%] flex flex-wrap justify-center gap-14 max-lg:gap-8 my-5 max-md:px-5">
                {ourServices.services.map((item: any, index) => (
                    <div key={index} className="w-full max-w-[20%] max-lg:max-w-[30%] max-md:max-w-[100%]">
                        <div className="flex items-center justify-center px-6 py-10 rounded-lg border-b-[6px] border-b-primary_light transition duration-300 min-h-[277px] max-md:min-h-auto h-[32vh] max-md:h-auto" style={{ boxShadow: boxShadowStyle }}>
                            <div>
                                <div className="w-[100px] h-[100px] p-3 rounded border-2 border-primary_light text-center mx-auto">
                                    <img src={item.icon} alt={item.name} />
                                </div>
                                <div className="mt-4 text-center font-semibold text-[25px]">
                                    <h3>{item.name}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OurService;