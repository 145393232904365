import { footer } from "../../config/footer";

const Footer = () => {
    return (
        <div className="w-full px-4 py-16 flex items-center justify-center mt-[300px] max-md:mt-[250px] bg-gradient-to-t from-primary_shade">
            <div className="text-center">
                <img className="w-[100px] h-[100px] mx-auto mb-4" src={footer.logo.url} alt={footer.logo.alt} />
                <p className="font-medium text-[22px] text-primary">{footer.copyrightText}</p>
            </div>
        </div>
    );
};

export default Footer;