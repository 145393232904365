export const heroSection = {
    backgroundCover: "../../public/assets/images/jpg/hero-section-background-cover-3.jpg", 
    subTitle: {
        first: "Welcome",
        second: "To Dinjii Zhuh Solutions",
    },
    title: "Indigenous Clean Energy Solutions",
    buttons: {
        one: {
            name: "Who We Are",
            url: "#who-we-are",
        },
        two: {
            name: "Send a Message",
            url: "#contact-form",
        }
    },
};